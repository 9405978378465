// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton } from "@material-ui/core";
import OrigoAppShell from "@oriola-origo/origo-app-shell";
import { Clear } from "@material-ui/icons";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
  },
  content: {},
  networkError: {
    backgroundColor: theme.palette.error.main,
    display: "flex",
    flexDirection: "colum",
    position: "absolute",
  },
  errorText: {
    margin: theme.spacing(1),
  },
}));

function NetworkError() {
  const classes = useStyles();
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const errors = [];
    // const createErr = (title, identifier, errorObject, showInModal) => {
    //   if (errorObject) {
    //     errors.push({ title, errorObject, identifier, showInModal });
    //   }
    // };

    setErrors(errors);
  }, [setErrors]);

  const clearErrors = () => {
    setErrors([]);
  };

  const renderDevelopmentErrors = () => {
    if (process.env.NODE_ENV === "development") {
      return (
        <div>
          {errors.length > 0 && (
            <div className={classes.networkError}>
              <div style={{ flexGrow: 1 }}>
                {errors.map((error, index) => (
                  <Typography
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    color="textSecondary"
                    className={classes.errorText}
                    variant="body1"
                  >
                    {error.title}: {JSON.stringify(error.errorObject)}
                  </Typography>
                ))}
              </div>
              <div>
                <IconButton onClick={clearErrors}>
                  <Clear />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  // show
  return <div>{renderDevelopmentErrors()}</div>;
}

const ensureNoTrailingSlash = path =>
  path.endsWith("/") ? path.slice(0, -1) : path;

function Layout({ children }) {
  const { tokenData } = useSelector(state => state.user);

  return (
    <OrigoAppShell
      origoUrl={ensureNoTrailingSlash(process.env.REACT_APP_ORIGO_URL)}
      queryParameters={window.location.search}
      activeApplicationId={process.env.REACT_APP_ORIGO_APP_ID}
      origoAccessToken={tokenData.accessToken}
    >
      <NetworkError />
      {children}
    </OrigoAppShell>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {};

export default Layout;
